export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/library/home.vue'),
    meta: {
      // contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      
    },
  },
  {
    path: '/author/:id',
    name: 'author',
    component: () => import('@/views/library/author.vue'),
    meta: {
      // contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      
    },
  },
  {
    path: '/articale/:id/delails',
    name: 'details',
    component: () => import('@/views/library/details.vue'),
    meta: {
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/FAQ',
    name: 'faq',
    component: () => import('@/views/library/pS/fQS.vue'),
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('@/views/library/pS/nS.vue'),
  },
  {
    path: '/Journals',
    name: 'jS',
    component: () => import('@/views/library/pS/jS.vue'),
  },
  {
    path: '/archive',
    name: 'a',
    component: () => import('@/views/library/pS/a.vue'),
  },
  {
    path: '/for-authors',
    name: 'fa',
    component: () => import('@/views/library/pS/fA.vue'),
  },
  {
    path: '/contact-us',
    name: 'cu',
    component: () => import('@/views/library/pS/cU.vue'),
  },
  {
    path: '/journal/:id/archive',
    name: 'dOJ',
    component: () => import('@/views/library/pS/dOJ.vue'),
  },
  
]
